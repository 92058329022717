export default [
    {
        key: "client",
        label: "client",
        thClass: "text-left",
        tdClass: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "status",
        label: "account",
        thClass: "text-left",
        tdClass: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "service",
        label: "service",
        thClass: "text-left",
        tdClass: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "agent",
        label: "agent",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "direction",
        label: "call date",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: true,
        visible: true,
    },
    {
        key: "duration",
        label: "Duration",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: true,
        visible: true,
    },
    {
        key: 'record_url',
        label: "Audio",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        visible: false,
        thStyle: { width: "250px" },

    },
    {
        key: "star",
        label: "Score",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: true,
        visible: false,

    },
    {
        key: "score",
        label: "Review by",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: true,
        visible: false,
    },
    {
        key: 'actions',
        label: "Actions",
        thClass: "text-center",
        tdClass: "text-center",
        sortable: false,
        visible: true,
    },
];
