<template>
  <b-modal
    v-model="showModal"
    :title="modalTitle"
    title-tag="h3"
    @hide="$emit('hide')"
  >
    <div style="padding: 0 30px">
      <div class="container-rating-qu">
        <div class="d-flex w-70 mt-1">
          <div
            v-for="item in stars"
            :key="item"
            class="container-star"
            @mouseover="toggleHoverStart(item)"
            @click="setRating(item)"
          >
            <feather-icon
              icon="StarIcon"
              size="44"
              class="star"
              style="font-weight: lighter !important"
              :class="{ 'star-active': starsActive.includes(item) }"
            />
          </div>
        </div>
      </div>
      <div class="mt-1">
        <div class="w-100 pb-1" style="font-size: 14px">Comment</div>
        <!-- <b-form-textarea id="content" rows="4" v-html="task.content" readonly /> -->
        <div id="quil-content-gusjhds-parent" style="">
          <quill-editor
            id="quil-content-gusjhds"
            v-model="comment"
            :options="editorOption"
            style="width: 100%"
            class="quill-br"
          />
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div
        class="w-100 d-flex justify-content-start gap-5"
        style="padding: 10px 30px 10px 30px"
      >
        <b-button variant="primary" @click="saveRating">
          <feather-icon icon="SaveIcon" size="16" />
          <span style="font-size: 18px"> Save </span>
        </b-button>
        <b-button variant="secondary" @click="$emit('hide')"> Cancel </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import CallReviewService from "@/views/quality/views/calls-review/services/calls-review.service.js";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";

export default {
  components: {
    quillEditor,
  },
  props: {
    reviewId: {
      type: Number,
      required: true,
    },

    client: {
      type: String,
      required: true,
    },
    agent: {
      type: String,
      required: true,
    },
  },

  computed: {
    modalTitle() {
      return `Call between ${this.client} and ${this.agent}`;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["code-block", "blockquote"],
          ],
        },
      },
      showModal: true,
      stars: [1, 2, 3, 4, 5],
      starsActive: [1],
      rating: 1,
      comment: "",
    };
  },

  methods: {
    toggleHoverStart(value) {
      if (this.rating > 0) return;
      this.starsActive = this.stars.slice(0, value);
    },

    setRating(rating) {
      if (this.rating == rating) {
        this.rating = 0;
        this.starsActive = [];
      } else {
        this.rating = rating;
        this.starsActive = this.stars.slice(0, rating);
      }
    },
    async saveRating() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      const params = {
        id: this.reviewId,
        score: this.rating,
        comment: this.comment,
        user_id: this.currentUser.user_id,
      };
      this.addPreloader();
      try {
        const { data, status } = await CallReviewService.update(params);
        if (status == 200) {
          this.$emit("refresh");
          this.showSuccessSwal();
        }
      } catch (e) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style lang="scss">
.container-rating-qu {
  width: 100%;
  display: flex;
  justify-content: start;

  .container-star {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .star {
      margin: 10px;
      fill: rgba($color: #000000, $alpha: 0);
      stroke-width: 1;
    }

    .star-active {
      color: #ffd12d;
      font-weight: light;
      fill: #ffd12d;
    }
  }
}

#quil-content-gusjhds {
  height: 200px;
  display: flex !important;
  flex-direction: column-reverse !important;
}

#quil-content-gusjhds .ql-container {
  border-top: 1px solid #ccc !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom: none !important;
}

#quil-content-gusjhds .ql-toolbar {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
</style>
