<template>
  <div>
    <b-card no-body class="mb-0">
      <filter-slot
        :filter="visibleFilters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @onChangeCurrentPage="$refs.rcTable.refresh()"
        @reload="$refs['rcTable'].refresh()"
      >
        <template #buttons>
          <b-button
            v-if="statusReview == 1"
            variant="success"
            class="ml-1"
            @click="generateMoreCalls()"
          >
            LOAD NEW CALLS
          </b-button>
        </template>

        <b-table
          id="rcTable"
          slot="table"
          ref="rcTable"
          :items="myProvider"
          :fields="fields"
          :filter="filter"
          table-class="text-nowrap"
          responsive="sm"
          no-border-collapse
          show-empty
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client)="data">
            <div class="text-warning font-weight-bolder">
              {{ data.item.client }}
            </div>
            <div>
              {{ data.item.lead_number }}
            </div>
          </template>

          <template #cell(status)="data">
            <div>
              <router-link
                v-if="data.item.client_account"
                class
                target="_blank"
                :to="`/quality/clients/account/${data.item.account_id}`"
                >{{ data.item.client_account }}</router-link
              >
              <b-badge v-else variant="light-primary">LEAD</b-badge>
            </div>
            <div>
              <div v-if="data.item.type_review == 1" class="w-10">
                <status-account :account="data.item" />
              </div>
              <div v-else>
                <b-badge
                  :variant="statusPotentialLead(data.item.name_status)[1]"
                >
                  {{ statusPotentialLead(data.item.name_status)[0] }}
                </b-badge>
              </div>
            </div>
          </template>

          <template #cell(service)="data">
            <div>
              {{ data.item.service }}
            </div>
            <div>
              {{ data.item.program_number }}
            </div>
          </template>

          <template #cell(record_url)="data">
            <div class="w-100 d-flex justify-content-around text-center">
              <div
                v-if="data.item.show_item && data.item.record_url"
                style="position: sticky; top: 0; z-index: 1"
              >
                <wave-surfer-player
                  style="height: 1.5rem"
                  :url="data.item.record_url"
                  :options="audioOptions"
                  :wave-surfer-id="`wave-surfer-${data.index}`"
                  :elapsed-time-width="50"
                  :file_name="data.item.original_id"
                  :download="false"
                  @loading="data.item.isLoading = true"
                  @ready="data.item.isLoading = false"
                  @WaveSurferEvents="load"
                />
              </div>
              <feather-icon
                v-else
                v-b-tooltip.hover
                icon="LoaderIcon"
                class="text-primary cursor-pointer"
                title="Load record"
                @click="data.item.show_item = !data.item.show_item"
              />
            </div>
          </template>

          <template #cell(direction)="data">
            <b-row>
              <b-col
                cols="3"
                class="d-flex justify-content-end align-items-center"
                style="padding: 0 !important"
              >
                <!-- <b-badge :variant="getCallStatus(data.item.direction)[0]"> -->
                <feather-icon
                  size="16"
                  class="m-1"
                  :class="getCallStatus(data.item.direction)[2]"
                  :icon="getCallStatus(data.item.direction)[1]"
                />
                <!-- </b-badge> -->
              </b-col>
              <b-col cols="6" style="padding: 0 0.5rem !important">
                <div>
                  <b-badge :variant="getCallStatus(data.item.direction)[0]">{{
                    data.item.direction
                  }}</b-badge>
                </div>
                <div>
                  {{ data.item.start_time | myGlobal }}
                </div>
              </b-col>
            </b-row>
          </template>

          <template #cell(duration)="data">
            <div class="w-100 d-flex justify-content-around text-center">
              {{ convertMSeconds(data.item.duration) }}
            </div>
          </template>
          <template #cell(star)="data">
            <div class="d-flex align-items-center justify-content-center">
              <div class="font-weight-bolder">
                {{ data.item.score }}
              </div>
              <feather-icon
                icon="StarIcon"
                class="cursor-pointer text-warning"
                size="20"
                style="margin-left: 5px; margin-bottom: 2px"
              />
            </div>
          </template>

          <template #cell(score)="data">
            <div>
              <div>
                {{ data.item.review_date | myGlobal }}
              </div>
              <div class="text-trnuncate">
                {{ data.item.review_by }}
              </div>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="w-100 d-flex justify-content-around text-center">
              <template v-if="statusReview == 1">
                <feather-icon
                  v-b-tooltip.hover
                  icon="FileTextIcon"
                  class="cursor-pointer"
                  title="Show note"
                  @click="openModalNote(data.item)"
                />

                <feather-icon
                  v-b-tooltip.hover
                  icon="StarIcon"
                  title="Add a rating"
                  class="cursor-pointer text-warning"
                  @click="openModalRating(data.item)"
                />
              </template>

              <feather-icon
                v-else
                v-b-tooltip.hover
                icon="BookIcon"
                title="Show comment"
                class="cursor-pointer text-warning"
                @click="openModalNote(data.item)"
              />
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>

    <modal-rating
      v-if="showModalRating"
      :review-id="currentReview"
      :agent="currentAgent"
      :client="currentClient"
      @hide="showModalRating = false"
      @refresh="refreshComponents"
    />

    <modal-show-note
      v-if="showModalNote"
      :note-account-id="currentNoteAccountId"
      :status-review="statusReview"
      :type-note="typeNote"
      @hide="showModalNote = false"
    />
  </div>
</template>

<script>
import Filters from "@/views/quality/views/calls-review/data/filters.data.js";
import Fields from "@/views/quality/views/calls-review/data/fields.data.js";
import { mapGetters } from "vuex";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";
import CallsReviewService from "@/views/quality/views/calls-review/services/calls-review.service.js";
import ModalRating from "@/views/quality/views/calls-review/components/ModalRating.vue";
import ModalShowNote from "@/views/quality/views/calls-review/components/ModalShowNote.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  name: "CallReview",
  components: { WaveSurferPlayer, ModalRating, ModalShowNote, StatusAccount },
  data() {
    return {
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Number, Client, Agent...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      filter: Filters,
      startPage: null,
      toPage: null,
      items: [],
      sortBy: "1",
      sortDesc: true,
      showModalRating: false,
      showModalNote: false,
      calls: [],
      typeNote: 1,

      currentReview: null,
      currentAgent: null,
      currentClient: null,
      currentNote: "",
      currentDateNote: null,

      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },

      currentNoteAccountId: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    statusReview() {
      const isConversation = this.$route.matched[2]
        ? this.$route.matched[2].meta.status
        : null;
      return isConversation;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    fields() {
      const fields = [...Fields];
      if (this.statusReview == 1) {
        fields.find((item) => item.key == "score").visible = false;
        fields.find((item) => item.key == "star").visible = false;
      } else {
        fields.find((item) => item.key == "score").visible = true;
        fields.find((item) => item.key == "star").visible = true;
      }
      return fields.filter((filter) => filter.visible);
    },

    visibleFilters() {
      const filters = [...Filters];
      if (this.statusReview == 1) {
        filters[2].visible = false;
        filters[3].visible = false;
        filters[6].visible = false;
      } else {
        filters[2].visible = true;
        filters[3].visible = true;
        filters[6].visible = true;
      }
      return filters;
    },
  },
  methods: {
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
    },

    resetSearch() {
      this.searchInput = "";
    },

    async generateMoreCalls() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        await CallsReviewService.store();
        this.$refs.rcTable.refresh();
      } catch (e) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async myProvider(ctx) {
      try {
        this.addPreloader();
        const params = {
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          from_date: this.filter[0].model,
          to_date: this.filter[1].model,

          from_review_date: this.filter[2].model,
          to_review_date: this.filter[3].model,

          program: this.filter[4].model,
          module: this.filter[5].model,
          score: this.filter[6].model,
          status: this.statusReview,
          sort_desc: ctx.sortDesc ? "desc" : "asc",
          sort_by: ctx.sortBy,
        };
        const { data } = await CallsReviewService.index(params);
        // this.startPage = data.from ? data.from : 0;
        // this.paginate.perPage = data.per_page;
        // this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        this.calls = data.data;
        return this.calls || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },
    getCallStatus(status) {
      if (status === "INBOUND") {
        return ["light-primary", "PhoneIncomingIcon", "text-primary"];
      }

      return ["light-success", "PhoneOutgoingIcon", "text-success"];
    },

    refreshComponents() {
      this.$refs.rcTable.refresh();
      this.showModalRating = false;
    },


    statusPotentialLead(status) {
      switch (status) {
        case "1":
          return ["Potential", "light-success"];
        case "2":
          return ["No potential", "light-info"];
        case "3":
          return ["Confirm", "light-primary"];
        case "4":
          return ["Recovery", "light-warning"];
      }
    },

    openModalRating(item) {
      this.currentReview = item.id;
      this.currentAgent = item.agent;
      this.currentClient = item.client;
      this.showModalRating = true;
    },

    openModalNote(note) {
      this.typeNote = note.type_review; // 1 note_account, 2 note
      this.currentNoteAccountId =
        note.type_review == 1 ? note.note_account_id : note.note_id;
      this.showModalNote = true;
    },
    closeModalNote() {
      this.showModalNote = false;
      this.currentNoteAccountId = null;
    },
  },
};
</script>
